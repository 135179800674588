@import 'cores/mixin';

// Color
$primary        : #181818;
$white          : #FFFFFF;
$bg-dark        : #181818;
$bg-light       : #F9F9F9;
$border         : #696969;
$text-secondary : #222222;
$text           : #1C1C1C;
$text-light     : #969696;
$hover          : #EBF3FE;
$link-red       : #F05644;
$link           : #2B59DA;
$success        : #4BB7AC;
$danger         : #B74B4B;
$warning        : #F3C44A;
$info           : #74CFE3;
$overlay        : #14161A;

/* Core style */
@import 'cores/fonts';
@import 'cores/base';

/* Component style */
// @import 'components/animation'
@import 'components/custom-plugins';
@import 'components/general';
@import 'components/header';
@import 'components/footer';
@import 'components/form';
@import 'components/content';
@import 'components/responsive';
// @import 'components/responsive'
