/* Header */
.header {
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  transition: all .3s ease-in-out;
  &__wrap {
    padding: 24px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__logo {
    img {
      max-height: 32px;
      &.dark {
        display: none; } } }
  &__menu {
    // position: relative
    padding-right: 180px;
    .menu {
      &-wrap {
        display: flex;
        align-items: center;
        margin-left: -32px;
        margin-right: -32px;
        transition: all .3s ease-in-out; }
      &-item {
        padding-left: 32px;
        padding-right: 32px;
        position: relative;
        a {
          color: $white;
          text-decoration: none;
          transition: all .3s ease-in-out; }
        &:hover {
          a {
            // color: $text
            color: #969696; } }
        &.current-menu-item {
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            background: #969696;
            // background: $white
            border-radius: 50%;
            position: absolute;
            left: 16px;
            top: 10px;
            transition: all .3s ease-in-out; }
          &:hover {
            &:before {
              // background: $text
              background: #969696; } } } } }
    .lang {
      position: absolute;
      top: 24px;
      right: 38px;
      max-width: 80px;
      .select {
        max-width: 100%;
        font-size: 12px;
        line-height: 1.5em;
        .btn {
          padding: 5px 10px;
          font-size: 12px;
          line-height: 1.5em;
          background: transparent;
          // border: 1px solid #FFF
          border: none !important;
          color: #FFF;
          &:focus,
          &:visited {
            outline: none !important;
            box-shadow: none !important; } }
        .dropdown-menu {
          font-size: 12px;
          line-height: 1.5em; }
        .filter-option-inner-inner {
          text-align: center; }
        .img {
          width: 28px;
          height: 16px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 auto 4px; } } } }
  @include responsive(1100px) {
    &__menu {
      padding-right: 0;
      .lang {
        top: 9px;
        right: 80px;
        .select {
          .btn {
            line-height: 1.2em;
            border-color: $text;
            color: $text; }
          .img {
            margin: 0 auto; } } } } }
  @include maxtablet {
    &__logo {
      img {
        max-height: 20px; } } }

  // Header Sticky
  &.sticky {
    background: $white;
    .header {
      &__logo {
        img {
          display: none;
          &.dark {
            display: block; } } }
      &__menu {
        .menu {
          &-item {
            a {
              color: $text;
              &:hover {
                color: #969696; } }
            &.current-menu-item {
              &:before {
                // background: $text
                background: #969696; }
              a {
                color: #969696; } } } }
        .lang {
          .select {
            .btn {
              border-color: $text;
              color: $text; } } } } } }

  &.header__secondary {
    .header {
      &__logo {
        img {
          display: none;
          &.dark {
            display: block; } } }
      &__menu {
        .menu {
          &-item {
            a {
              color: $text; }
            &.current-menu-item {
              &:before {
                background: $text; } } } } } }
    .lang {
      .select .btn {
        color: #1C1C1C;
        border-color: #1C1C1C; } } } }
