/* Contents */

// Homepage
.home {
  &__banner {
    position: relative;
    .slider-item {
      max-height: calc(100vh - 88px);
      overflow-y: hidden;
      .box-img {
        width: 100%;
        max-height: calc(100vh - 88px);
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 100%;
          padding: 45% 0; }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom; } } }

    .owl-dots {
      position: absolute;
      right: 0;
      bottom: -88px;
      width: 100%;
      max-width: 50vw;
      padding: 40px 40px 70px;
      padding-top: 131px;
      z-index: 3;
      background: $white;
      display: flex;
      .owl-dot {
        width: 123px;
        height: auto;
        padding: 8px 0 !important;
        // background: #E2E2E2
        transition: all .3s ease-in-out;
        span {
          display: block;
          width: 100%;
          height: 2px;
          background: #e2e2e2; }
        &.active {
          span {
            background: #969696; } }
        & + .owl-dot {
          margin-left: 24px; } } }
    &-navigation {
      position: absolute;
      // right: 0
      right: -40px;
      // bottom: -88px
      // bottom: 60px
      bottom: 0;
      width: 100%;
      max-width: 50vw;
      // padding: 40px 40px 70px
      z-index: 2;
      .slider-index {
        font-size: 14px;
        line-height: 20px;
        color: $text-light; }
      .slider-title {
        // max-width: 560px
        // max-width: 38vw
        max-width: 600px;
        h2 {
          font-size: 32px;
          line-height: 49px;
          // margin-bottom: 40px
          color: #878787;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          a {
            color: #878787;
            text-decoration: none; }
          b {
            font-weight: 400;
            color: #1C1C1C; }
          i {
            display: none; } } } }
    .slider.no-slider {
      & + .home__banner-navigation {
        padding: 32px 40px 80px;
        background: $white;
        margin-bottom: -88px; } } }
  &__sliderfull {
    .slider-item {
      max-height: 100vh;
      .box-img {
        max-height: 100vh;
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #000;
          opacity: .2; } }
      .box-text {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 40px;
        // color: #878787
        color: #FFFF;
        .slider-index {
          transition: all .3s linear;
          transform: translateX(-60%);
          opacity: 0;
          transition-delay: 1.2s; }
        h2 {
          transition: all .3s linear;
          transform: translateX(60%);
          width: 100%;
          opacity: 0;
          transition-delay: 1.2s;
          margin-bottom: 0; }
        a {
          color: #FFFF;
          text-decoration: none; }

        b {
 } } }          // color: #1C1C1C
    .owl-item.active {
      .slider-item {
        .box-text {
          .slider-index {
            opacity: 1;
            transform: translateX(0); }
          h2 {
            opacity: 1;
            transform: translateX(0); } } } }
    .owl-nav {
      position: absolute;
      top: calc(50% - 16px);
      left: 0;
      width: 100%;
      padding: 0 40px;
      margin: auto 0;
      display: flex;
      justify-content: space-between;
      opacity: .5;
      transition: all .3s ease-in-out;
      .icon {
        width: 32px;
        height: 32px;
        display: block;
        background-color: #FFF;
        background-image: url(../images/ic-arrow-right.svg);
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        &.nav-prev {
          transform: rotate(180deg); } } }
    .owl-dots {
      position: absolute;
      right: 0;
      bottom: 0;
      background: transparent;
      padding: 40px;
      justify-content: flex-end;
      .owl-dot {
        width: 123px;
        height: auto;
        padding: 8px 0 !important;
        // background: #E2E2E2
        transition: all .3s ease-in-out;
        span {
          display: block;
          width: 100%;
          height: 2px;
          background: #e2e2e2; }
        &.active {
          span {
            background: #1C1C1C; } }
        & + .owl-dot {
          margin-left: 24px; } } }
    &:hover {
      .owl-nav {
        opacity: 1; } } }
  .scroll-down {
    background: #F4F5F6;
    padding: 24px 40px;
    img {
      width: 40px;
      height: 40px;
      animation: arrow_down 2s ease-in-out infinite; }
    a {
      position: relative;
      &:before {
        // content: ""
        // width: 2px
        // height: 31px
        // background: #F4F5F6
        // position: absolute
        // left: 19px
 } } }        // top: 0
  &__story {
    color: $white;
    &-top {
      background: #1C1C1C; }
    .quote {
      padding: 164px 0 72px;
      color: $white;
      position: relative;
      z-index: 2;
      p {
        // font-size: 32px
        // line-height: 48px
        font-size: 21px;
        line-height: 32px;
        margin-bottom: 12px; }
      &-writer {
        color: $white;
        opacity: .5; } }
    .box__video {
      position: relative;
      padding: 42% 0;
      &-thumbnail {
        position: absolute;
        top: 0;
        left: -208px;
        height: 100%;
        width: 62vw;
        // max-width: 1036px
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
      .btn-play {
        width: 56px;
        height: 56px;
        position: absolute;
        right: 200px;
        bottom: -28px;
        background: $white;
        border-radius: 50%;
        z-index: 3;
        img {
          height: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 0 3px; }
        &:hover {
          cursor: pointer; } } }
    &-bottom {
      background: #181818;
      padding: 120px 0 104px;
      .content {
        font-size: 16px;
        line-height: 28px;
        p {
          &:last-child() {
            margin-bottom: 32px; } } }
      .btn-arrow {
        color: $white;
        text-decoration: none;
        img {
          width: 24px;
          height: 24px;
          margin-top: -8px;
          margin-left: 12px;
          object-fit: cover; } } } }

  &__aqupdate {
    padding: 104px 0;
    background: #F9F9F9;
    a {
      color: $text; } }
  &__projects {
    padding: 104px 0 80px;
    &-title {
      margin-bottom: 32px;
      &.hide {
        .btn-warrow {
          display: none; } } } } }


.btn-warrow {
  display: inline-block;
  color: $text-secondary;
  text-decoration: none;
  transition: all .3s ease;
  span,
  .icon {
    display: inline-block;
    vertical-align: middle;
    transition: all .3s ease-in-out; }
  .icon {
    margin-top: -6px;
    margin-left: 12px;
    width: 24px;
    height: 24px; }
  &:hover {
    text-decoration: none;
    color: $text-secondary;
    .icon {
      margin-left: 16px; } } }

.aq {
  &__card {
    margin-bottom: 48px;
    .box-img {
      width: 100%;
      padding: 30.613% 0;
      position: relative;
      margin-bottom: 16px;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; } }
    .box-content {
      h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px; }
      .date {
        font-size: 14px;
        line-height: 20px;
        color: $text-light; } } } }

.projects {
  .section__banner {
    max-height: calc(100vh - 80px);
    .banner__item img {
      max-height: calc(100vh - 80px); } }
  &__wrap {
    background: $white;
    .pagination {
      width: 100%;
      text-align: center;
      justify-content: center;
      .page-numbers {
        display: inline-block;
        vertical-align: middle;
        padding: 0 8px;
        color: $text-light;
        &.current {
          color: $text; } } } }
  &__menu {
    padding: 44px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    &-title {
      h1 {
        font-size: 24px;
        line-height: 32px;
        @include font-700();
        margin-bottom: 0; } }
    &-wrap {
      display: flex;
      align-items: center;
      .menu-item {
        & + .menu-item {
          margin-left: 32px; }
        a {
          color: $text-light;
          text-decoration: none; }
        &:hover,
        &.active {
          a {
            color: $text; } }
        &.dropdown {
          // .dropdown-item
          //   .menu-item
          //     a
          //       &:active,
          //       &:visited,
          //       &:focus
 } } } }          //         background: #e9ecef

  &__post {
    margin-bottom: 80px;
    .tagline {
      margin-bottom: 80px; }
    &-slider.slider2 {
      .owl-item.active + .owl-item.active {
        .slider-item {
          .box-content {
            opacity: 0; } } }
      .slider-item {
        .box-img {
          // img
 } }          //   height: 704px
      &.no-slider {
        & + .box-name {
          margin-top: 0; }
        .owl-nav {
          display: none; } }
      &.no-nav {
        .owl-nav {
          display: none; } } }
    .box-name {
      // margin-top: -40px
      transition: all .5s ease-in-out;
      a {
        color: $text;
        text-decoration: none;
        &:hover {
          color: #969696; } }
      h3 {
        margin-bottom: 0; }
      span {
        font-size: 14px;
        line-height: 20px;
        color: $text-light; } } }

  &__testi {
    margin-bottom: 104px;
    .box-content {
      margin-bottom: 24px; }
    .box-writer {
      display: flex;
      align-items: center;
      .box-photo {
        width: 40px;
        height: 40px;
        margin-right: 24px;
        border-radius: 50%;
        overflow: hidden; }
      .box-name {
        b,
        span {
          display: block; }
        b {
          font-weight: 400; }
        span {
          color: $text-light; } } } }

  .slider2 {
    .owl-nav {
      bottom: -5px; } }

  .single {}
  article {
    img:not(.alignleft):not(.alignright):not(.aligncenter) {
      display: block;
      margin: 0 auto 50px; }
    .gallery {
      margin-left: -12px !important;
      margin-right: -12px !important;
      margin-bottom: 48px !important;
      &-item {
        padding-left: 12px;
        padding-right: 12px;
        border: 0;
        margin-bottom: 0 !important;
        img {
          border: 0 !important;
          margin-bottom: 0 !important; } }
      @include mobile() {
        &-item {
          float: none !important;
          width: 100% !important; } } } } }

.dropdown {
  .dropdown-item {
    &:active {
      background-color: #e9ecef; } } }
.single {
  &__hq {
    padding: 72px 0 80px;
    h2 {
      margin-bottom: 8px; }
    p.text-light {
      color: #969696 !important;
      margin-bottom: 32px; }
    .details-architecs {
      margin-bottom: 24px;
      table {
        width: 100%;
        th {
          font-size: 14px;
          line-height: 20px;
          padding: 8px 0;
          border-top: 1px solid #E2E2E2;
          font-weight: normal;
          color: #969696; } }
      tbody {
        td {
          padding: 8px 0; } }
      .profile {
        width: 100%;
        display: flex;
        align-items: center;
        .pp {
          width: 48px;
          height: 48px;
          margin-right: 12px;
          border-radius: 50%;
          overflow: hidden; } } }
    article {
      .box-img {
        margin-bottom: 32px; }
      img {
        height: auto; }
      a {
        color: #969696; }
      iframe {
        display: block;
        margin: 0 auto 24px;
        position: relative;
        &:before {
          content: "";
          width: 100%;
          padding: 50% 0; } } } }
  &__handout {
    padding: 0 0 80px;
    .box-img {
      margin-bottom: 80px; }
    .box-text {
      padding-left: 100px; }
    .box-download {
      padding: 16px 0;
      border-top: 1px solid $border;
      ul {
        margin-bottom: 32px;
        li {
          &:not(:last-child) {
            margin-bottom: 16px; } } } }
    .btn-download {
      padding: 16px 32px;
      display: block;
      width: 100%;
      border: 2px solid #1C1C1C;
      color: $text;
      text-align: center; } }
  &__morePrjct {
    padding: 104px 0;
    background: $bg-light; } }


.detail__post {
  article {
    & > *:not(div):not(img):not(iframe) {
      max-width: 600px;
      margin-left: 100px; }
    // & > p
    //   max-width: 600px
    //   margin-left: 100px
    .box-img {
      margin-bottom: 32px; }
    a {
      color: #969696; }
    img {
      display: block;
      margin: 0 auto 24px;
      height: auto; }
    iframe {
      display: block;
      max-width: 100%;
      margin: 0 auto 24px; } } }

.slider2 {
  .owl-stage {
    left: 0px !important;
    display: flex; }
  .owl-nav {
    position: absolute;
    bottom: 66px;
    right: 28px;
    .owl-prev,
    .owl-next {
      width: 56px;
      height: 56px;
      background: $white;
      border-radius: 50%;
      border: 1px solid #E2E2E2 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background-color: $white !important;
      background-repeat: no-repeat !important;
      background-size: 24px 24px !important;
      background-position: center !important;
      &.disabled {
        box-shadow: none; } }
    .owl-prev {
      background-image: url('../images/ic-arrow-left.svg') !important; }
    .owl-next {
      background-image: url('../images/ic-arrow-right.svg') !important;
      margin-left: 16px; } }
  .owl-item {
    &.active {
      .slider-item {
        .box-content {
          opacity: 1;
          visibility: visible;
          a {
            text-decoration: none;
            color: $text; } } } } }
  .slider-item {
    .box-img {
      margin-bottom: 24px;
      img {
        width: auto;
        // height: 560px
        max-height: 560px;
        object-fit: cover; } }
    .box-content {
      opacity: 0;
      visibility: hidden;
      transition: all .5s ease-in-out;
      a {
        color: $text;
        text-decoration: none; }
      h3 {
        margin-bottom: 0; }
      span {
        font-size: 14px;
        line-height: 20px;
        color: $text-light; } } }

  &.no-slider {
    margin-bottom: 24px;
    display: flex !important;
    .slider-item {
      flex: 0 0 auto;
      max-width: 100%;
      margin-right: 24px;
      .box-img {
        margin-bottom: 0;
        img {
          height: 560px; } }
      .box-content {
        opacity: 1;
        visibility: visible; } } }
  &.full-slider {
    .box-img {
      margin-bottom: 0;
      img {
        width: 100vw;
        max-height: 730px; } }
    .owl-nav {
      bottom: -28px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      @extend .container;
      .owl-prev,
      .owl-next {
        &.disabled {
          display: none; } } } } }

.uniqeSlider {
  width: 100vw;
  max-width: 1450px;
  .slider-item {
    padding-left: 60px;
    position: relative;
    .box {
      &-content {
        position: absolute;
        transform-origin: bottom left;
        transform-style: preserve-3D;
        transform: rotate(-90deg);
        position: absolute;
        left: 45px;
        bottom: 0;
        // left: 0
        // bottom: -45px
        // transform-origin: 0 0
        // transform: rotate(-90deg)
        a {
          text-decoration: none;
          color: $text; }
        h3 {
          margin-bottom: 0; }
        .text-light {
          color: #969696 !important; } }
      &-img {
        img {
          max-height: 560px;
          object-fit: cover; } } } }
  .owl-nav {
    position: absolute;
    // bottom: 30px
    bottom: -30px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 200px;
    .owl-prev,
    .owl-next {
      width: 56px;
      height: 56px;
      background: $white;
      border-radius: 50%;
      border: 1px solid #E2E2E2 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background-color: $white !important;
      background-repeat: no-repeat !important;
      background-size: 24px 24px !important;
      background-position: center !important;
      &.disabled {
        box-shadow: none;
        display: none; } }
    .owl-prev {
      background-image: url('../images/ic-arrow-left.svg') !important; }
    .owl-next {
      background-image: url('../images/ic-arrow-right.svg') !important;
      margin-left: 16px;
 } }      // margin-right: 200px
  .owl-item {
    &.active {
      .slider-item {
        .box-content {
          opacity: 1;
          visibility: visible; } } } }
  &.no-slider {
    width: 100%;
    max-width: 100%; } }
.slider-awwards {
  .owl-nav {
    position: absolute;
    // bottom: 30px
    bottom: -130px;
    left: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 200px;
    width: 100%;
    max-width: 990px;
    .owl-prev,
    .owl-next {
      width: 56px;
      height: 56px;
      background: $white;
      border-radius: 50%;
      border: 1px solid #E2E2E2 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background-color: $white !important;
      background-repeat: no-repeat !important;
      background-size: 24px 24px !important;
      background-position: center !important;
      &.disabled {
        box-shadow: none;
        display: none; } }
    .owl-prev {
      background-image: url('../images/ic-arrow-left.svg') !important; }
    .owl-next {
      background-image: url('../images/ic-arrow-right.svg') !important;
      margin-left: 16px;
 } }      // margin-right: 200px

  &.no-slider {
    display: block; } }

.verticalSlider {
  .owl-nav {
    margin-top: 32px;
    display: flex;
    .owl-prev,
    .owl-next {
      width: 56px;
      height: 56px;
      background: $white;
      border-radius: 50%;
      border: 1px solid #E2E2E2 !important;
      box-shadow: 6px 0px 4px rgba(0, 0, 0, 0.25);
      background-color: $white !important;
      background-repeat: no-repeat !important;
      background-size: 24px 24px !important;
      background-position: center !important;
      &.disabled {
        box-shadow: none;
        display: none; } }
    .owl-prev {
      background-image: url('../images/ic-arrow-left.svg') !important;
      transform: rotate(90deg);
      margin-left: 16px;
      order: 2; }
    .owl-next {
      background-image: url('../images/ic-arrow-right.svg') !important;
      transform: rotate(90deg);
      order: 1; } } }


.aqupdates {
  &__wrap {
    padding: 104px 0; }
  &__formig {
    padding: 104px 0;
    background: #F9F9F9;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      h2 {
        font-size: 24px;
        line-height: 32px; }
      .gotoig {
        a {
          text-decoration: none;
          color: $primary; }
        .ic {
          display: inline-block;
          vertical-align: middle;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          img {
            width: 100%;
            height: 100%;
            display: block; } }
        span {
          display: inline-block;
          vertical-align: middle;
          color: $primary; } } }
    .sbi {
      &_item {}
      &_photo {
        max-width: 240px;
        img {
          max-height: 240px; } } }
    .jr-insta-thumb {
      ul.thumbnails.jr_col_4 {
        li {
          width: auto !important;
          margin: 0; } }
      img.GraphImage,
      img.GraphSidecar,
      img {
        max-height: 240px !important;
        object-fit: cover; } }

    .pllexislider .slides img {
      max-height: 240px !important;
      object-fit: cover; } }

  .aq__card {
    a {
      color: $text; } } }


.contact {
  padding: 200px 0 60px;
  // background: #F9F9F9
  background: linear-gradient(90deg, rgba(255,255,255,1) 45%, rgba(249,249,249,1) 45%);
  &__form {
    h1 {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 48px; } } }
.branch-contact {
  height: 100%;
  width: 100%;
  display: flex;
  // align-items: flex-end
  .row {
    width: 100%;
    margin-left: -40px;
    margin-right: -40px;
    & > [class*="col-"] {
      padding-left: 40px;
      padding-right: 40px; } } }

.branch-item {
  font-size: 14px;
  line-height: 20px;
  &-name {
    margin-bottom: 11px;
    p {
      margin-bottom: 0; }
    small {
      color: $text-light; } }
  address {
    margin-bottom: 32px; }
  a {
    color: $text;
    text-decoration: none; }
  ul {
    li:not(:last-child) {
      margin-bottom: 4px; } } }

.services {
  &__philosophy {
    padding: 104px 0 72px;
    h3 {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 24px; } }
  &__approach,
  &__process {
    padding: 52px 0; }
  &__process {
    padding: 104px  0 236px;
    background-color: #F9F9F9;
    background-image: url('../images/vision-image.svg');
    background-size: 59.5% 100%;
    background-repeat: no-repeat;
    background-position: calc(100% + 90px) bottom;
    // min-height: 756px
    h2 {
      margin-bottom: 100px; }
    &-steps {
      small {
        color: $text-light; }
      // opacity: 0
      // visibility: hidden
      // height: 0
      // display: none
      .row {
        // opacity: 0
        // transition: all .3s ease-in-out
 }        // transform: translateY(50px)

      // &.active
      //   opacity: 1
      //   visibility: visible
      //   transform: translateY(0)
      //   // height: max-content
      //   .row
      //     opacity: 1
 } }      //     transform: translateY(0px)
  &__sliderApp {
    .full-slider {
      // width: 100vw
      // max-width: 1452px
      width: calc(100% + (100vw - 1248px) / 2);
      .owl-nav {
        padding-right: 40px; }
      .box-img {
        img {
          max-width: 808px;
          max-height: 480px; } } } } }

// About
.about {
  &__keyppl {
    position: relative;
    &:before {
      content: "";
      width: 100%;
      padding: 12.5% 0;
      background: $bg-light;
      position: absolute;
      top: 0;
      left: 0; }
    .keyppl__wrap {
      width: 65vw;
      .owl-item {
        .col-md-4 {
          max-width: 100%;
          flex: 0 0 100%; } } }
    .keyppl__card {
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 320px;
      .box-img {
        margin-bottom: 16px;
        position: relative;
        &:before {
          content: "";
          padding: 50% 0;
          display: block; }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top; } }
      .box-content {
        // height: calc(100% - 360px)
        // display: flex
        // // flex-wrap: wrap
        // flex-direction: column
        margin-bottom: 24px;
        h3 {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 8px; }
        h3,
        p,
        a {
          // flex: 1 1 100%
          // width: 100%
 } }          // display: block
      .btn-warrow {
        margin-top: auto;
        span {
          color: $text-light; }
        .svg {
          path {
            stroke: $text-light; } } } } }
  &__awwards {
    background: #F9F9F9;
    position: relative;
    z-index: 3;
    .slider-awwards {
      .owl-nav {}
      .owl-dots {
        display: none; } } }
  &__career {
    & > p + ul {
      margin-top: 64px; }
    ul#accordion {
      & > li {
        // padding-bottom: 16px
        border-bottom: 1px solid #E2E2E2;
        &:not(:last-child) {
          margin-bottom: 16px; }
        p {
 } } }          // margin-bottom: 0
    .card-header {
      background-color: transparent;
      padding: 0;
      border-bottom: 0;
      padding-bottom: 16px;
      &:hover {
        cursor: pointer; }
      p {
        margin-bottom: 0; } }
    .collapse {
      margin-bottom: 16px;
      p {
 }        // margin-bottom: 24px
      ul {
        list-style: disc;
        padding-left: 30px;
        li {
          margin-bottom: 16px; } } }
    .btn-wrap {
      text-align: right;
      padding-top: 32px; }
    .button-contact {
      padding: 8px 24px;
      width: auto;
      display: inline-block;
      text-decoration: none;
      transition: all .3s ease;
      &:hover,
      &:focus {
        background-color: #1C1C1C;
        color: #FFF; } } } }
.modalStory {
  .modal-body {
    padding-bottom: 60px; }
  .btn-close {
    &:hover {
      cursor: pointer; } }
  h3 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px; } }

.slider-awwards {
  width: calc(100% + 340px);
  // max-width: 1452px
  .box-awwards {
    padding: 24px;
    background: #FFF;
    max-width: 288px;
    margin-right: 24px;
    .icon {
      width: 40px;
      text-align: center;
      margin-bottom: 4px;
      img {
        width: 32px;
        height: 32px; } }
    .name {} }
  .owl-nav {
    bottom: -130px; }
  .owl-dots {
    display: none; } }

[class*="about__"] {
  padding: 104px 0;
  h2 {
    font-size: 24px;
    line-height: 32px; }
  h3 {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px; } }


// Static Page
.static-page {
  padding: 200px 0 60px;
  .box-img {
    margin-bottom: 24px; } }
