/* Fonts */

@include fontGenerator("Gordita",(400),(gordita-regular));
@include fontGenerator("Gordita Medium",(400),(gordita-medium));
@include fontGenerator("Gordita Bold",(400),(gordita-bold));

@mixin font-400 {
  font-family: "Gordita", sans-serif; }

@mixin font-600 {
  font-family: "Gordita Medium", sans-serif; }

@mixin font-700 {
  font-family: "Gordita Bold", sans-serif; }
