/* Footer */
.footer {
  background: $bg-dark;
  padding: 72px 0;
  color: $white;
  font-size: 14px;
  line-height: 20px;
  &__wrapper {
    & > .container > .row > .col-md-3 {
      display: flex; } }
  &__company {
    &-item {
      margin-bottom: 40px;
      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 4px; } } }
      &:last-child {
        margin-bottom: 0; } }
    h4 {
      margin-bottom: 0;
      color: $white; }
    small {
      opacity: .5; }
    a {
      color: $white;
      text-decoration: none; } }
  &__socmed {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    a {
      width: 32px;
      height: 32px;
      img {
        width: 100%;
        height: 100%; }
      & + a {
        margin-left: 24px; } } }
  &__bottom {
    padding-top: 72px;
    font-size: 12px;
    line-height: 16px;
    a,
    span {
      opacity: .5; }
    .row {
      justify-content: space-between; }
    .col {
      &:last-child {
        text-align: right; } } }
  &__cpyright {
    opacity: .5; }
  &__pp {
    a,
    span {
      color: $white;
      display: inline-block;
      text-decoration: none;
      & + a,
      & + span {
        margin-left: 24px; } } }
  @include maxtablet {
    &__socmed {
      justify-content: flex-start;
      margin-top: 32px; }
    &__bottom {
      .row {
        .col {
          flex: 0 0 100%;
          max-width: 100%;
          &:first-child {
            order: 2;
            margin-top: 16px;
            text-align: center; }
          &:last-child {
            order: 1;
            text-align: center; } } } } } }


.backto-top {
  padding: 28px 44px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .arrow-up {
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
    img {
      width: 100%;
      height: 100%;
      animation: arrow_up 2s ease-in-out infinite; } } }

.wa-chat {
  position: fixed;
  bottom: 100px;
  right: 80px;
  z-index: 2;
  a {
    display: block;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      display: block; } } }



