/* Boostrap */
@media (min-width: 576px) {
  .container {
    max-width: 540px; }
  .container-small {
    max-width: 480px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; }
  .container-small {
    max-width: 620px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; }
  .container-small {
    max-width: 860px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1248px; }
  .container-small {
   max-width: 1048px; } }

.container,
.container-small {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  margin-left: -12px;
  margin-right: -12px;
  &> .col,
  &> [class*="col-"] {
    padding-left: 12px;
    padding-right: 12px; } }

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: $text;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal; }

h1 {
  font-size: 1.75rem;
  line-height: 1.5em;
  @include font-400(); }

h2 {
  font-size: 1.5rem;
  line-height: 1.5em;
  @include font-400(); }

h3 {
  font-size: 1rem;
  line-height: 1.5em;
  @include font-400(); }

h4 {
  font-size: 1rem;
  line-height: 1.5em;
  @include font-400(); }

h5 {
  font-size: 0.875rem;
  line-height: 1.571em;
  @include font-400(); }

h6 {
  font-size: 0.875rem;
  line-height: 1.571em;
  @include font-400(); }

small {
  font-size: 0.875rem;
  line-height: 1.571em;
  @include font-400();
  color: $text-light; }

a {
  color: $link;
  transition: color .3s ease-in-out;
  &:hover {
    color: $link;
    text-decoration: underline; } }

p {
  margin: 0 0 1.5rem;
  &:last-child {
    margin-bottom: 0; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }


article {
  p {
    word-break: keep-all;
    /* Non standard for webkit */
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    &:last-child {
      margin-bottom: 0; } }

  a {
    &:hover {
      text-decoration: underline; } }

  blockquote {
    font-style: italic; }

  li {
    ul, ol {
      margin: 0 1.5rem;
      padding-top: 10px; } }

  ul, ol {
    margin: 0 0 2rem;
    padding-left: 1.5rem;
    &:last-child {
      margin: 0; }
    li {
      margin-bottom: .75rem;
      &:last-child {
        margin: 0; } } }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } } }


/* Base */
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%; }

hr {
  border: 0;
  border-top: 1px solid $border; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

// custom scrollbar
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  &::-webkit-scrollbar-track {
    background: #fff; }
  &::-webkit-scrollbar-thumb {
    background: #333;
    border: 1px solid #FFF;
    border-radius: 0; }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(#333,.4); } }


html, body {
  font-size: 16px;
  line-height: 24px;
  // font-family: "Graphik Reguler", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
  @include font-400();
  font-weight: normal;
  padding: 0;
  margin: 0;
  background-color: $white;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  @include mobile {
    font-size: 14px;
    line-height: 1.5em; } }
