/* responsive */
@include responsive(0, 1950px) {
  .services__process {
    background-size: 50.5% 100%; } }

@include responsive(1500px) {
  .section__banner {
    &.half-banner {
      .banner__content {
        max-width: 55vw;
        padding: 50px;
        h2 {
          font-size: 20px;
          line-height: 1.5em; } } } }
  .about__keyppl {
    .keyppl__card {
      max-width: 260px; } } }

@include responsive(1400px) {

  .services {
    &__process {
      padding: 80px 0;
      max-height: 100vh;
      background-size: 59.5% 80%; } } }

@include responsive(1100px) {
  .header {
    &__wrap {
      padding: 15px 20px; }
    &__logo {}
    .menu-wrap {
      position: absolute;
      top: -20%;
      left: 0;
      display: block;
      width: 100%;
      height: calc(100vh - 50px);
      background: $white;
      padding: 16px;
      visibility: hidden;
      opacity: 0;
      margin-left: 0;
      margin-right: 0;
      .menu-item {
        transition: all .3s ease;
        transform: translateX(-50%);
        margin-bottom: 24px;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        padding-left: 40px; } }
    .menu-icon {
      display: block;
      width: 30px;
      height: 16px;
      position: relative;
      margin: 0;
      // margin-right: 24px
      cursor: pointer;
      transition: all .25s ease-in-out;
      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $primary;
        position: absolute;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(opacity .5s .5s,top .5s .4s,transform .5s);
        &:nth-child(1) {
          top: 0; }
        &:nth-child(2) {
          top: 7px; }
        &:nth-child(3) {
          top: 14px; } } }
    &__menu {
      .menu-item a {
        color: #1C1C1C; } } }

  body.main-menu-open {
    .header {
      background: #FFF;
      &__logo {
        img {
          display: none;
          &.dark {
            display: block; } } }
      .menu-icon {
        span {
          @include transition(opacity .5s,top .5s,transform .5s .4s);
          &:nth-child(1) {
            @include transform(rotate(45deg));
            top: 7px; }
          &:nth-child(2) {
            opacity: 0; }
          &:nth-child(3) {
            @include transform(rotate(-45deg));
            top: 7px; } } }
      .menu-wrap {
        top: 50px;
        visibility: visible;
        opacity: 1; }
      .menu-item {
        transform: translateX(0);
        padding-left: 8px;
        &:nth-child(1) {
          transition-delay: .5s; }
        &:nth-child(2) {
          transition-delay: .6s; }
        &:nth-child(3) {
          transition-delay: .7s; }
        &:nth-child(4) {
          transition-delay: .8s; }
        &:nth-child(5) {
          transition-delay: .9s; } } } }

  .slider2.full-slider {
    .owl-nav {
      padding-right: 15%; } } }


@include ltablet() {
  .project-block {
    .item-container {
      max-width: 800px;
      &> .col {
        max-width: 384px;
        flex: 0 0 384px; } } } }



@include maxtablet() {
  [class*="about__"] {
    padding: 40px 0;
    h2 {
      margin-block-end: 32px; }
    h3 {
      font-size: 21px;
      line-height: 1.5em; } }

  .slider2 {
    .owl-nav {
      bottom: 38px; } }
  .section {
    &__banner {
      &.half-banner {
        .banner__item {
          padding-bottom: 0;
          img {
            max-width: 100%;
            max-height: 100%;
            height: 50vh !important; } }
        .banner__content {
          position: relative;
          max-width: 100%;
          padding: 24px;
          h2 {
            font-size: 24px;
            line-height: 1.5em; }
          p {
            br {
              display: none; } } } } } }

  .home {
    &__banner {
      &-navigation {
        max-width: 100%;
        bottom: 20px;
        right: 0;
        background: #fff;
        padding: 24px;
        .slider-title {
          h2 {
            font-size: 24px;
            line-height: 1.5em;
            margin-bottom: 0; } } }
      .owl-dots {
        background: #FFF;
        max-width: 100%;
        padding: 10px 24px;
        padding-top: 10px;
        bottom: 0; }

      .slider-item {
        max-height: 100vh;
        .box-img {
          max-height: 100vh;
          &:before {
            padding: 43% 0; } } } }
    &__slider {
      margin-bottom: 16px;
      height: calc(100vh - 140px);
      .slider-item {
        .box-img {
          height: 100vh;
          max-height: calc(100vh - 180px); } } }
    &__sliderfull {
      .slider-item {
        .box-text {
          padding: 20px;
          bottom: 40px; } }
      .owl-nav {
        padding: 0 20px; } }

    &__story {
      .quote {
        padding: 72px 0;
        p {
          font-size: 21px;
          line-height: 1.5em; } }
      .box__video-thumbnail {
        left: 0;
        width: 100%; }
      .box__video {
        .btn-play {
          right: 15%; } }
      &-bottom {
        padding: 60px 0 52px; } }
    &__aqupdate {
      padding-bottom: 72px; }

    .scroll-down {
      padding: 20px;
      img {
        height: 20px;
        width: 20px; } }
    &__projects {
      padding: 60px 0; } }

  .uniqeSlider {
    .owl-item {
      .slider-item {
        .box-content {
          // left: 10px
 }          // bottom: -40px
        .box-img {
          img {
            max-height: 260px; } } } }
    .owl-nav {
      padding-right: 20%; } }

  .about {
    &__keyppl {
      .keyppl__wrap {
        width: 100%; }
      .keyppl__card {
        .box-img {
          margin-bottom: 32px; } } }
    .slider-awwards {
      .owl-nav {
        padding-right: 15%;
        bottom: -64px; } } }
  .modalStory {
    .box-img {
      margin-bottom: 32px; } }


  .projects {
    &__menu {
      display: block;
      position: relative;
      z-index: 10;
      &-title {
        margin-bottom: 24px; }
      &-wrap {
        position: relative;
        .menu-item {
          & + .menu-item {
            margin-left: 24px; } }
        .menu-item {
          & > a {
              display: block;
              text-overflow: ellipsis;
              width: 100%;
              max-width: 90px;
              white-space: nowrap;
              overflow: hidden; } } } }


    &__post {
      .tagline {
        margin-bottom: 32px; } }

    &.single {}
    .detail__post {
      article {
        & > *:not(div):not(img):not(iframe) {
          margin-left: 0;
          max-width: 100%; } } }
    .project__post {
      margin-bottom: 50px; }
    .single__hq {
      padding: 50px 0; }
    .single__morePrjct {
      padding: 50px 0; }

    .details-architecs {
      overflow-x: auto;
      table {
        min-width: 600px; } } }

  .services {
    &__philosophy {
      padding: 50px 0;
      h3 {
        font-size: 24px;
        line-height: 1.5em; } }
    &__process {
      padding: 50px 0;
      background-size: 50%;
      background-position: right bottom;
      h2 {
        margin-bottom: 32px; }
      &-steps {
        width: 100%; } }
    .project__post {
      margin-bottom: 50px; }
    &__sliderApp {
      .full-slider {
        width: 100%; } } }

  .masthead {
    .story-count {
      &__item {
        .number {
          font-size: 2.5rem;
          line-height: 1.167em; } } } }
  .project-block {
    .item-container {
      max-width: 600px;
      &> .col {
        max-width: 284px;
        flex: 0 0 284px; } } }

  .contact {
    padding: 120px 0 24px;
    &__form {
      h1 {
        font-size: 24px;
        line-height: 1.5em; } }
    .branch-contact {
      padding-top: 60px;
      address {
        margin-bottom: 16px; }
      .col-md-6 {
        margin-bottom: 32px; } } }

  .aqupdates {
    .section__banner.half-banner {
      .banner__item img {
        height: auto; } }
    &__wrap {
      padding: 50px 0 0; }
    &__formig {
      padding: 50px 0;
      &-title {
        display: block; } } }

  #start_project {
    h2 {
      font-size: 1.5rem;
      line-height: 1.167em; } }

  .alm-reveal > .col {
    max-width: 284px;
    flex: 0 0 284px; } }

@include mobile() {
  .home {
    &__banner {
      .slider-item {
        .box-img {
          &:before {
            padding: 80% 0; } } } } }

  .masthead {
    .rate {
      display: none;
      margin: 0 -0.5rem 2rem;
      &__item {
        padding: 0 0.5rem; } }
    .story-count {
      margin: 2rem -1rem 0;
      &__item {
        padding: 0 1rem;
        .number {
          font-size: 2rem;
          line-height: 1.167em; } } }
    &__title {
      font-size: 2.5rem;
      line-height: 1.333em;
      margin-bottom: 2rem; }
    &__content {
      max-width: 100%;
      font-size: 1rem;
      line-height: 1.5em;
      p {
        font-size: 1rem;
        line-height: 1.5em; } } }

  .project-block {
    &__title {
      font-size: 1.5rem;
      list-height: 1.25em;
      transform: translate(0, 0); }
    .item-container {
      max-width: 80%;
      &> .col {
        max-width: 100%;
        flex: 0 0 100%; } }
    .item {
      margin-bottom: 2.5rem; } }

  #projectsItemContainer {
    .col:nth-child(odd),
    .col:nth-child(even) {
      transform: scale(1.5) translate(0, 0);
      transform-origin: top center; } }

  .alm-reveal > .col {
    max-width: 100%;
    flex: 0 0 100%; }
  .alm-reveal .col:nth-child(odd),
  .alm-reveal .col:nth-child(even) {
    transform: scale(1.5) translate(0, 0);
    transform-origin: top center; }

  .logo-grid {
    flex-wrap: wrap;
    .item {
      max-width: 25%;
      flex: 0 0 25%;
      padding: 0 6px 12px; } }

  .much-more {
    display: flex;
    padding: 2.5rem 0 0;
    margin: 0 -1rem;
    .item {
      padding: 0 1rem;
      font-size: 0.75rem;
      line-height: 1.5em;
      img {
        max-height: 56px;
        width: auto; } } }

  .testimonials-grid {
    &__top {
      padding: 0 1rem 6rem;
      .item {
        margin-bottom: 2rem;
        &__title {
          font-size: 1.25rem;
          line-height: 1.333em; }
        &:not(:last-child) {
          padding-bottom: 2rem;
          &:after {
            width: 70%;
            height: 1px;
            top: 100%;
            right: 15%;
            background: #EAEAEA; } } } } }

  .content-block {
    padding: 1.5rem 0;
    span.sub {
      font-size: 1.5rem;
      list-height: 1.25em; }
    &__title {
      font-size: 1.5rem;
      list-height: 1.25em; } }

  .partners-block {
    padding-top: 6rem; }

  .partners-block--about {
    padding-top: 0; }

  .much-more-block {
    padding-bottom: 5rem; }

  .our-service-block {
    padding-top: 5rem;
    padding-bottom: 5rem; }

  .testimonials-block {
    padding-top: 6rem;
    padding-bottom: 6rem; } }
