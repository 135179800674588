/* General */
main {
  position: relative;
  z-index: 2;
  background: #FFFFFF;
  transition: all .5s ease-in-out;
  &.bg-dark {
    background: #222222; }
  &.bg-white {
    background: #FFFFFF; } }

.preload {
  opacity: 0;
  visibility: hidden;
  transition: all .8s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  &_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    animation-name: zoom-out;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .6;
      position: absolute;
      top: 0;
      left: 0; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &_text {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;
    @include mobile {
      padding: 1rem; }
    h2 {
      // font-size: 2rem
      // line-height: 1.5em
      font-size: 4rem;
      line-height: 1.5em;
      max-width: max-content;
      @include font-600();
      color: #FFF;
      text-align: left;
      transition:  all .3s ease-in-out;
      @include responsive(1500px) {
        font-size: 2.8rem; }
      @include maxtablet {
        font-size: 2rem; }
      @include mobile {
        font-size: 1.5rem; }
      span {
        display: block;
        width: 0;
        max-width: max-content;
        overflow: hidden;
        // border-right: .15em solid orange
        white-space: nowrap;
        // margin: 0 auto
        letter-spacing: .15em;
        &:nth-child(1) {
          // animation-delay: 6s
          // animation: typing 3s steps(40, end), blink-caret .75s step-end infinite
          // animation: typing 1s steps(30, end) 3s
          animation-name: typing;
          animation-delay: 3s;
          animation-duration: 1.5s;
          animation-timing-function: steps(50, end);
          animation-fill-mode: forwards; }
        &:nth-child(2) {
          // animation-delay: 10s
          // animation: typing 1s steps(50, end) 4s
          animation-name: typing;
          animation-delay: 3.5s;
          animation-duration: 2s;
          animation-timing-function: steps(50, end);
          animation-fill-mode: forwards; } } } }
  &.zoomin {
    .preload_img,
    .preload_text {
      opacity: 1;
      animation-name: zoom-in;
      animation-delay: 2s;
      animation-duration: 1.5s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards; } } }


.preload-on {
  height: 100vh;
  overflow: hidden;
  .preload {
    opacity: 1;
    visibility: visible; } }

.section__banner {
  .banner__item {
    width: 100%;
    max-height: 100vh;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      object-fit: cover;
      object-position: center bottom; } }
  &.half-banner {
    .banner__item {
      background: $text;
      // padding-bottom: 80px
      img {
        // max-width: 63%
        max-width: 100%;
        height: 100vh;
        max-height: calc(100vh - 80px); } }
    .banner__content {
      // background: $primary
      background: #181818cc;
      padding: 64px;
      // padding-bottom: 10.5%
      color: $white;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 44.524vw;
      h2 {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 8px;
        color: $white;
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: #969696; } } } } } }


.section__title {
  position: relative;
  margin-bottom: 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
    padding-right: 100px; }
  .seeall {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 24px;
    a {
      color: $text-secondary;
      text-decoration: none; }
    .icon,
    span {
      display: inline-block;
      vertical-align: middle; }
    .icon {
      width: 24px;
      height: 24px;
      margin-left: 12px;
      img {
        width: 100%;
        height: 100%;
        margin-top: -8px; } } } }


.text-light {
  color: $text-light; }


/* == WordPress WYSIWYG Editor Styles == */

.entry-content img {
  margin: 0 0 1.5em 0; }

.alignleft, img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left; }

.alignright, img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right; }

.aligncenter, img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both; }

.alignnone, img.alignnone {
  /* not sure about this one */ }

.wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;

  img {
    border: 0 none;
    padding: 0;
    margin: 0; }

  p.wp-caption-text {
    line-height: 1.5;
    font-size: 10px;
    margin: 0; } }

.wp-smiley {
  margin: 0 !important;
  max-height: 1em; }

blockquote {
  &.left {
    margin-right: 20px;
    text-align: right;
    margin-left: 0;
    width: 33%;
    float: left; }

  &.right {
    margin-left: 20px;
    text-align: left;
    margin-right: 0;
    width: 33%;
    float: right; } }

.gallery {
  dl, dt, dd {}

  dl {
    a, img {} } }

.gallery-caption, .size-full, .size-large, .size-medium, .size-thumbnail {}


// Animation Scroll
.animation-on-scroll {
  opacity: 0;
  transition: all .3s linear;
  &.appear-up {
    opacity: 0;
    transform: translateY(1.5rem);
    &.animate-in {
      opacity: 1;
      transform: translateY(0); } }
  &.appear-right {
    opacity: 0;
    transform: translateX(-1.5rem);
    &.animate-in {
      opacity: 1;
      transform: translateX(0); } }
  &.appear-left {
    opacity: 0;
    transform: translateX(1.5rem);
    &.animate-in {
      opacity: 1;
      transform: translateX(0); } } }

section.animation-on-scroll {
  * {
    opacity: 0;
    transform: translateY(1.5rem); }
  &.animate-in {
    * {
      opacity: 1;
      transform: translateY(0); } } }


@keyframes arrow_down {
  0% {
    transform: translate(0, -10px);
    opacity: 0; }

  50% {
    opacity: 1; }

  100% {
    transform: translate(0, 20px);
    opacity: 0; } }
@keyframes arrow_up {
  0% {
    transform: translate(0, -10px);
    opacity: 0; }

  50% {
    opacity: 1; }

  100% {
    transform: translate(0, 10px);
    opacity: 0; } }

@keyframes zoom-in {
	0% {
		transform: scale(1);
		opacity: 1; }
	100% {
		transform: scale(1.5);
		opacity: 0; } }

@keyframes zoom-out {
	0% {
		transform: scale(1.5);
		opacity: 0; }
	100% {
		transform: scale(1);
		opacity: 1; } }

/* The typing effect */
@keyframes typing {
  from {
    width: 0; }
  to {
    width: 100%; } }

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to {
    border-color: transparent; }
  50% {
    border-color: black; } }
