/* Custom Plugins */

.bootstrap-select {
  position: relative;
  & > .dropdown-toggle {
    @include font-400();
    position: relative;
    color: $text;
    background: $white;
    border: 1px solid $border;
    padding: 0.4375rem 2.5rem 0.4375rem 0.75rem;
    // &:after
    //   content: ""
    //   display: block
    //   width: 1rem
    //   height: 1rem
    //   background: url(../images/ic-arrow-down.svg)
    //   background-position: center
    //   background-size: contain
    //   position: absolute
    //   right: 0.75rem
    //   top: calc(50% - 0.5rem)
    &:hover {
      color: $text;
      background: $hover; }
    &:focus {
      color: $text;
      outline: none !important; }
    .filter-option-inner-inner {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }
  .dropdown-menu {
    max-width: 100%; }
  .no-results {
    white-space: initial;
    word-break: break-all; }

  .bs-ok-default:after {
    border-width: 0 .1em .1em 0;
    border-color: $primary; }
  &.show-tick .dropdown-menu .selected span.check-mark {
    top: 0.5rem; } }



// Dropdown
.dropdown {
  &-item {
    color: #1c1c1c;
    &.active {
      background: #969696;
      color: #1C1C1C !important; } } }


.modal-header {
  justify-content: flex-end; }
